import getRequests from "@/plugins/requests/getRequests";
import getRequest from "@/plugins/requests/getRequest";
import postRequest from "@/plugins/requests/postRequest";
import putRequest from "@/plugins/requests/putRequest";
import i18n from "@/locales/i18n"
import { useToast } from 'vue-toastification'
import axios from "axios";

const toast = useToast()

export default {
    actions: {
        fetchFinances(context, filterProps = {}) {
            let financeUrl = '';

            if (filterProps.type) {
                financeUrl += '&type=' + filterProps.type;
            }

            if (filterProps.periodFrom) {
                financeUrl += '&from=' + filterProps.periodFrom;
            }

            if (filterProps.periodTo) {
                financeUrl += '&to=' + filterProps.periodTo;
            }

            if (filterProps.name) {
                financeUrl += '&name=' + filterProps.name;
            }

            if (filterProps.officeExpenses) {
                financeUrl += '&officeExpenses=' + filterProps.officeExpenses;
            }

            if (filterProps.companyExpenses) {
                financeUrl += '&companyExpenses=' + filterProps.companyExpenses;
            }

            if (filterProps.users) {
                financeUrl += '&users=' + filterProps.users;
            }

            if (filterProps.employees) {
                financeUrl += '&employees=' + filterProps.employees;
            }

            if (filterProps.partners) {
                financeUrl += '&partners=' + filterProps.partners;
            }

            if (filterProps.currencies) {
                financeUrl += '&currencies=' + filterProps.currencies;
            }

            if (filterProps.fromCashiers) {
                financeUrl += '&fromCashiers=' + filterProps.fromCashiers;
            }

            if (filterProps.toCashiers) {
                financeUrl += '&toCashiers=' + filterProps.toCashiers;
            }

            if (filterProps.firms) {
                financeUrl += '&firms=' + filterProps.firms;
            }

            if (filterProps.isAccountant) {
                financeUrl += '&isAccountant=' + filterProps.isAccountant;
            }

            if (filterProps.isCompany) {
                financeUrl += '&isCompany=' + filterProps.isCompany;
            }

            return new Promise((resolve, reject) => {
                axios.get(`/finances?page=${filterProps.forExcel ? filterProps.pageForExcel : filterProps.page}&items-per-page=${filterProps.forExcel ? filterProps.itemsPerPageForExcel : filterProps.itemsPerPage}${financeUrl}`)
                    .then((response) => {
                        console.log(response)

                        let finances = {
                            models: response.data.finances['hydra:member'],
                            totalItems: response.data.totalItems,
                            pagesCount: response.data.pagesCount,
                            totalUsd: response.data.totalUsd,
                            totalUzs: response.data.totalUzs,
                            totalUzsInUsd: response.data.totalUzsInUsd
                        }

                        context.commit(filterProps.forExcel ? 'updateFinancesForExcel' : 'updateFinances', finances)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchStats(context, filterProps = {}) {
            let statsUrl = '';

            if (filterProps.periodFrom) {
                statsUrl += '&from=' + filterProps.periodFrom;
            }

            if (filterProps.periodTo) {
                statsUrl += '&to=' + filterProps.periodTo;
            }

            const area = filterProps.area || ''

            return getRequests(
                `/finances/stats?area=${filterProps.area}` + statsUrl,
                area === 'firm' ? 'updateStatsFirmExp' :
                            area === 'office' ? 'updateStatsOfficeExp' :
                            area === 'acc-income' ? 'updateStatsAccIncome' :
                            area === 'acc-expense' ? 'updateStatsAccExpense' :
                            area === 'cashier-income' ? 'updateStatsCashierIncome' :
                            area === 'cashier-expense' ? 'updateStatsCashierExpense' : 'noMutation',
                context
            )
        },
        fetchFinance(context, financeId) {
            return getRequest('/finances/' + financeId, 'updateFinance', context)
        },
        fetchFinancesStatement(context) {
            return getRequests('/finances/statement', 'updateStatement', context)
        },
        fetchBossesFinance(context, filterProps = {}) {
            let financeUrl = '';

            if (filterProps.periodFrom) {
                financeUrl += '?from=' + filterProps.periodFrom;
            }

            if (filterProps.periodFrom && filterProps.periodTo) {
                financeUrl += '&to=' + filterProps.periodTo;
            } else if (filterProps.periodFrom === '' && filterProps.periodTo) {
                financeUrl += '?to=' + filterProps.periodTo;
            }

            return getRequests('/finances/bosses' + financeUrl, 'updateBossesFinance', context)
        },
        pushOfficeExpense(context, finance) {
            return postRequest('/finances/office/expense', finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        pushCompanyExpense(context, finance) {
            return postRequest('/finances/company/expense', finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        pushFinanceTransfer(context, finance) {
            return postRequest('/finances/transfer', finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        pushFinanceAccountantIncome(context, finance) {
            return postRequest('/finances/accountant/income', finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        pushFinanceAccountantExpense(context, finance) {
            return postRequest('/finances/accountant/expense', finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        putOfficeExpense(context, finance) {
            return putRequest('/finances/office/expense/' + finance.id, finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        putCompanyExpense(context, finance) {
            return putRequest('/finances/company/expense/' + finance.id, finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        putFinanceTransfer(context, finance) {
            return putRequest('/finances/transfer/' + finance.id, finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        putFinanceAccountantExpense(context, finance) {
            return putRequest('finances/accountant/expense/' + finance.id, finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
        putFinanceAccountantIncome(context, finance) {
            return putRequest('finances/accountant/income/' + finance.id, finance, 'updateFinance', context)
                .then(() => {
                    toast.success(i18n.global.t('errorMessages.successfullySaved'), {
                        timeout: 3000,
                    });
                })
        },
    },

    mutations: {
        updateFinances(state, finances) {
            state.finances = finances
        },
        updateFinancesForExcel(state, financesForExcel) {
            state.financesForExcel = financesForExcel
        },
        updateStatsOfficeExp(state, statsOfficeExp) {
            state.statsOfficeExp = statsOfficeExp
        },
        updateStatsFirmExp(state, statsFirmExp) {
            state.statsFirmExp = statsFirmExp
        },
        updateStatsAccIncome(state, statsAccIncome) {
            state.statsAccIncome = statsAccIncome
        },
        updateStatsAccExpense(state, statsAccExpense) {
            state.statsAccExpense = statsAccExpense
        },
        updateStatsCashierIncome(state, statsCashierIncome) {
            state.statsCashierIncome = statsCashierIncome
        },
        updateStatsCashierExpense(state, statsCashierExpense) {
            state.statsCashierExpense = statsCashierExpense
        },
        updateStatement(state, statement) {
            state.statement = statement
        },
        updateFinance(state, finance) {
            state.finance = finance
        },
        updateBossesFinance(state, bossesFinance) {
            state.bossesFinance = bossesFinance
        },
        noMutation() {}
    },

    state: {
        finances: {
            models: [],
            totalItems: 0,
            pagesCount: 0,
            totalUsd: 0,
            totalUzs: 0,
            totalUzsInUsd: 0,
        },
        financesForExcel: {
            models: [],
            totalItems: 0,
            pagesCount: 0,
            totalUsd: 0,
            totalUzs: 0,
            totalUzsInUsd: 0,
        },
        statsOfficeExp: {
            models: [],
            totalItems: 0
        },
        statsFirmExp: {
            models: [],
            totalItems: 0
        },
        statsAccIncome: {
            models: [],
            totalItems: 0
        },
        statsAccExpense: {
            models: [],
            totalItems: 0
        },
        statsCashierIncome: {
            models: [],
            totalItems: 0
        },
        statsCashierExpense: {
            models: [],
            totalItems: 0
        },
        statement: {
            models: [],
            totalItems: 0
        },
        bossesFinance: {
            models: [],
            totalItems: 0,
        },
        finance: {
            id: 0,
            sum: 0,
            calculated: 0,
            usdRate: null,
            name: '',
            isCash: false,
            user: {
                id: 0,
                person: {
                    givenName: '',
                    familyName: ''
                }
            },
            employee: {
                id: 0,
                person: {
                    givenName: '',
                    familyName: ''
                }
            },
            partner: {
                id: 0,
                debt: 0,
                calculated: 0,
                name: '',
                isCustomer: false,
                currency: {
                    id: 0,
                    name: ''
                },
            },
            type: {
                id: 0,
                name: ''
            },
            officeExpense: {
                id: 0,
                name: ''
            },
            companyExpense: {
                id: 0,
                name: ''
            },
            currency: {
                id: 0,
                name: ''
            },
            fromBalance: {
                id: 0,
                sum: 0,
                currency: {
                    id: 0,
                    name: ''
                },
                cashier: {
                    id: 0,
                    name: ''
                }
            },
            toBalance: {
                id: 0,
                sum: 0,
                currency: {
                    id: 0,
                    name: ''
                },
                cashier: {
                    id: 0,
                    name: ''
                }
            },
            firm: {
                id: 0,
                name: ''
            },
            isAccountant: false,
            inputtedAt: '',
            details: [
                {
                    id: 0,
                    contentUrl: ''
                }
            ]
        },
    },

    getters: {
        getFinances(state) {
            return state.finances.models
        },
        getFinancesForExcel(state) {
            return state.financesForExcel
        },
        getFinancesCounts(state) {
            return state.finances
        },
        getStatsOfficeExp(state) {
            return state.statsOfficeExp.models
        },
        getStatsFirmExp(state) {
            return state.statsFirmExp.models
        },
        getStatsAccIncome(state) {
            return state.statsAccIncome.models
        },
        getStatsCashierIncome(state) {
            return state.statsCashierIncome.models
        },
        getStatsCashierExpense(state) {
            return state.statsCashierExpense.models
        },
        getStatsAccExpense(state) {
            return state.statsAccExpense.models
        },
        getStatement(state) {
            return state.statement
        },
        getFinance(state) {
            return state.finance
        },
        getBossesFinance(state) {
            return state.bossesFinance.models
        }
    }
}